<template>
  <div class="detail">
    <div class="wrap">
      <div class="top">
        <div class="image">
          <swiper
            :slides-per-view="1"
            :pagination="{
              clickable: true,
            }"
            :navigation="false"
            :modules="modules"
            :virtual="true"
          >
            <swiper-slide
              v-for="(value, index) in this.product_images"
              :key="index"
              :virtualIndex="index"
            >
              <div
                class="image"
                :style="{ backgroundImage: 'url(' + value + ')' }"
              ></div>
            </swiper-slide>
          </swiper>
        </div>
        <div class="info">
          <div class="brand section">
            <div class="name">{{ seller_name }}</div>
            <div class="icon" @click="this.goto_store"></div>
          </div>
          <div class="name section">{{ product_name }}</div>
          <div class="brief section">{{ product_summary }}</div>
          <div class="price section">
            <span class="percent" v-if="discount_rate > 0"
              >{{ this.common.number_format(discount_rate * 100.0, 1) }}%</span
            >
            <span class="sale">{{
              this.common.currency_format(this.sale_price, this.unit)
            }}</span>
            <span class="retail">{{
              this.common.currency_format(this.retail_price, this.unit)
            }}</span>
          </div>
          <div class="user section">
            <ul>
              <li>
                <span class="number">{{
                  this.common.number_format(review_count)
                }}</span>
                {{ this.$t("product.detail.review_count") }}
              </li>
              <li>
                (<span class="number">{{
                  this.common.number_format(review_score)
                }}</span
                >/5.0)
              </li>
              <li>
                <span class="number">{{
                  this.common.number_format(sold_count)
                }}</span>
                {{ this.$t("product.detail.purchase_count") }}
              </li>
            </ul>
          </div>
          <div class="shipping section">
            {{ this.$t("product.detail.free_shipping") }}
          </div>
          <div class="marketing section">
            {{ this.$t("product.detail.genuine_korea") }}
          </div>
          <div
            class="option section"
            v-if="
              product_option != null && this.product_option.option.length > 0
            "
          >
            <div
              class="group"
              v-for="(option_data, option_index) in this.product_option.option"
              :key="option_index"
            >
              <div class="title" v-if="option_data.type == 'COLOR'">
                {{ this.$t("product.detail.color") }}
              </div>
              <div class="title" v-else-if="option_data.type == 'SIZE'">
                {{ this.$t("product.detail.size") }}
              </div>
              <div class="title" v-else>{{ option_data.name }}</div>
              <div class="data">
                <div
                  class="item"
                  v-for="(option_value, value_index) in option_data.values"
                  :key="value_index"
                  :class="[option_value.selected ? 'selected' : '']"
                  @click="select_option(option_index, option_value.id)"
                >
                  {{ option_value.value }}
                </div>
              </div>
            </div>
          </div>
          <div
            class="selection section"
            v-if="product_option_combination.length > 0"
          >
            <div
              class="combination"
              v-for="(value, index) in product_option_combination"
              :key="index"
            >
              <div class="amount">
                <div
                  class="minus"
                  @click="decrease_combination_amount(value.id)"
                ></div>
                <input type="number" :value="value.amount" readonly />
                <div
                  class="plus"
                  @click="increase_combination_amount(value.id)"
                ></div>
              </div>
              <div class="value">
                <span class="name">{{ value.name }}</span>
                <span class="price">{{
                  this.common.currency_format(
                    (sale_price + value.extra_price) * value.amount,
                    this.unit
                  )
                }}</span>
              </div>
              <div
                class="remove"
                @click="remove_option_combination(value.id)"
                v-if="
                  product_option != null &&
                  this.product_option.option.length > 0
                "
              ></div>
            </div>
          </div>
          <div class="total section">
            <div class="title">{{ this.$t("product.detail.total_price") }}</div>
            <div class="price">
              {{ this.common.currency_format(this.total_price, this.unit) }}
            </div>
          </div>
          <div class="buttons section">
            <button class="button red purchase" @click="buy_now">
              {{ this.$t("product.detail.button_buy") }}
            </button>
            <button
              class="button outline red cart"
              @click="add_to_cart"
            ></button>
            <!-- <button class="button wish">
              {{ this.$t("product.detail.button_wish") }}
            </button> -->
          </div>
        </div>
        <!--// info -->
      </div>
      <!--// TOP -->

      <div class="link">
        <div class="menu" @click="scroll_to_element('DETAIL')">
          {{ this.$t("product.detail.menu_detail") }}
        </div>
        <div class="menu" @click="scroll_to_element('REVIEW')">
          {{ this.$t("product.detail.menu_review") }}
          <span v-if="this.review_count > 0"
            >({{ this.common.number_format(review_count) }})</span
          >
        </div>
        <div class="menu" @click="scroll_to_element('QNA')">
          {{ this.$t("product.detail.menu_qna") }}
          <span v-if="this.qna_total_count > 0"
            >({{ this.common.number_format(qna_total_count) }})</span
          >
        </div>
        <div class="menu" @click="scroll_to_element('EXTRA')">
          {{ this.$t("product.detail.menu_extra_info") }}
        </div>
      </div>

      <!-- Avatar -->
      <div class="avatar">
        <div
          class="character"
          v-if="this.product_item_id != null && this.product_item_id > 0"
        >
          <div
            class="layer"
            v-for="(value, index) in this.avatar_layers"
            :key="index"
            :style="{ backgroundImage: 'url(' + value.image_url + ')' }"
          ></div>
          <div class="title">
            {{ this.$t("product.detail.avatar_coordi") }}
            <div class="help">
              <div class="bubble">
                {{ this.$t("product.detail.avatar_coordi_desc") }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <!--// Avatar -->

      <div class="detail" ref="detail" v-html="product_detail"></div>

      <!-- review -->
      <div class="subtitle" ref="review">
        {{ this.$t("product.detail.review") }} ({{
          this.common.number_format(review_count)
        }})
      </div>
      <div class="content">
        <ul class="review">
          <li v-if="this.review_data.length <= 0" class="empty">
            {{ this.$t("product.detail.no_review") }}
          </li>
          <li v-for="(value, index) in this.review_data" :key="index">
            <div class="header">
              <div
                class="profile"
                :style="{
                  backgroundImage: 'url(' + value.avatar_thumbnail_url + ')',
                }"
              ></div>
              <div class="id" v-if="!this.storage.is_logged_in()">
                {{ this.common.mask_name(value.user_name) }}
              </div>
              <div
                class="id"
                v-else-if="value.user_id != this.storage.get_user_id()"
              >
                {{ this.common.mask_name(value.user_name) }}
              </div>
              <div class="id" v-else>
                {{ value.user_name }}
              </div>
              <img
                class="flag"
                :src="
                  require('@/assets/img/flag/' + value.user_country + '.svg')
                "
              />
              <div class="date">
                {{
                  this.common.date_long_format(
                    this.common.date_parse(value.register_date)
                  )
                }}
              </div>
            </div>
            <div class="body">
              <Rating
                :rate="value.review_score"
                :readonly="true"
                class="score"
              ></Rating>
              <div class="spec">
                <ul>
                  <li>{{ value.product_option }}</li>
                </ul>
              </div>
              <div class="comment">{{ value.review_content }}</div>
              <div class="image_wrap">
                <div
                  class="image"
                  :style="{
                    backgroundImage: 'url(' + value.review_image_url1 + ')',
                  }"
                  @click="this.open_image_zoom(value.review_image_url1)"
                  v-if="value.review_image_url1 != ''"
                ></div>
                <div
                  class="image"
                  :style="{
                    backgroundImage: 'url(' + value.review_image_url2 + ')',
                  }"
                  @click="this.open_image_zoom(value.review_image_url2)"
                  v-if="value.review_image_url2 != ''"
                ></div>
                <div
                  class="image"
                  :style="{
                    backgroundImage: 'url(' + value.review_image_url3 + ')',
                  }"
                  @click="this.open_image_zoom(value.review_image_url3)"
                  v-if="value.review_image_url3 != ''"
                ></div>
              </div>
            </div>
          </li>
        </ul>

        <button
          class="button outline small sharp compact more"
          v-if="this.review_data.length > 0 && !this.review_fully_loaded"
          @click="this.load_review_data"
        >
          {{ this.$t("product.detail.load_more") }}
        </button>
      </div>
      <!--// review -->

      <!-- Q&A -->
      <div class="subtitle" ref="qna">
        {{ this.$t("product.detail.qna") }} ({{
          this.common.number_format(this.qna_total_count)
        }})
        <button
          class="button small compact sharp qna_write"
          @click="open_qna_write_popup"
          v-if="this.storage.is_logged_in()"
        >
          {{ this.$t("product.detail.write_qna") }}
        </button>
      </div>
      <div class="content">
        <ul class="qna">
          <li v-if="this.qna_data.length <= 0" class="empty">
            {{ this.$t("product.detail.no_qna") }}
          </li>
          <li v-for="(value, index) in this.qna_data" :key="index">
            <div class="header">
              <div
                class="profile"
                :style="{
                  backgroundImage: 'url(' + value.avatar_thumbnail_url + ')',
                }"
              ></div>
              <div class="name" v-if="!this.storage.is_logged_in()">
                {{ this.common.mask_name(value.user_name) }}
              </div>
              <div
                class="name"
                v-else-if="value.user_id != this.storage.get_user_id()"
              >
                {{ this.common.mask_name(value.user_name) }}
              </div>
              <div class="name" v-else>
                {{ value.user_name }}
              </div>
              <img
                class="flag"
                :src="
                  require('@/assets/img/flag/' + value.user_country + '.svg')
                "
              />
              <div class="status">
                {{
                  value.question_status == "ANSWERED"
                    ? this.$t("product.detail.qna_answered")
                    : this.$t("product.detail.qna_unanswered")
                }}
              </div>
              <div class="date">
                {{
                  this.common.date_long_format(
                    this.common.date_parse(value.question_register_date)
                  )
                }}
              </div>
            </div>
            <div class="question">
              <img
                class="lock"
                src="@/assets/img/icon_lock.svg"
                v-if="value.secret == 'YES'"
              />
              {{
                value.is_hidden == "YES"
                  ? this.$t("product.detail.secret_content")
                  : value.question_content
              }}
            </div>
            <div class="answer" v-if="value.question_status == 'ANSWERED'">
              <div class="header">
                <div class="name">{{ value.seller_name }}</div>
                <div class="date">
                  {{
                    value.answer_register_date == null ||
                    value.answer_register_date == ""
                      ? ""
                      : this.common.date_long_format(
                          this.common.date_parse(value.answer_register_date)
                        )
                  }}
                </div>
              </div>
              <div class="body">
                <img
                  class="lock"
                  src="@/assets/img/icon_lock.svg"
                  v-if="value.secret == 'YES'"
                />{{
                  value.is_hidden == "YES"
                    ? this.$t("product.detail.secret_content")
                    : value.answer_content
                }}
              </div>
            </div>
          </li>
        </ul>

        <button
          class="button outline small sharp compact more"
          v-if="this.qna_data.length > 0 && !this.qna_fully_loaded"
          @click="this.load_qna_data"
        >
          {{ this.$t("product.detail.load_more") }}
        </button>
      </div>
      <!--// Q&A -->

      <!-- 추가 정보 -->
      <div class="subtitle" ref="extra">
        {{ this.$t("product.detail.menu_extra_info") }}
      </div>
      <div class="content policy">
        <ul>
          <li>
            <div
              class="title"
              :class="{ opened: this.is_policy_shipping_opened }"
              @click="this.toggle_policy('SHIPPING')"
            >
              {{ this.$t("product.detail.policy_shipping") }}
            </div>
            <Transition name="slide">
              <div
                class="policy_content"
                v-html="this.policy_shipping"
                v-if="this.is_policy_shipping_opened"
              ></div>
            </Transition>
          </li>
          <li>
            <div
              class="title"
              :class="{ opened: this.is_policy_return_opened }"
              @click="this.toggle_policy('RETURN')"
            >
              {{ this.$t("product.detail.policy_return") }}
            </div>
            <Transition name="slide">
              <div
                class="policy_content"
                v-html="this.policy_return"
                v-if="this.is_policy_return_opened"
              ></div>
            </Transition>
          </li>
        </ul>
      </div>
      <!--// 추가 정보 -->

      <!-- Seller's product -->
      <div class="subtitle">
        {{ seller_name }}{{ this.$t("product.detail.seller_products") }}
        <div class="more">
          <router-link :to="'/store/' + this.seller_id">{{
            this.$t("product.detail.load_more")
          }}</router-link>
        </div>
      </div>
      <div class="content">
        <ul class="product">
          <li
            v-for="(value, index) in this.seller_product_data"
            v-bind:key="index"
          >
            <div
              class="image"
              :style="{
                backgroundImage:
                  'url(' +
                  this.common.get_product_image_url(
                    value.product_id,
                    value.product_images
                  ) +
                  ')',
              }"
              @click="this.$router.push('/product/detail/' + value.product_id)"
            ></div>
            <div class="desc">
              <div class="seller">{{ value.seller_name }}</div>
              <div class="name">
                <router-link
                  :to="{
                    name: 'detail',
                    params: { product_id: value.product_id },
                  }"
                  >{{ value.product_name }}</router-link
                >
              </div>
              <div class="price">
                <span
                  class="percent"
                  v-show="value.retail_price - value.sale_price > 0"
                  >{{
                    this.common.number_format(
                      Math.floor(
                        ((value.retail_price - value.sale_price) /
                          value.retail_price) *
                          100.0
                      )
                    )
                  }}%</span
                >
                <span class="sale">{{
                  this.common.currency_format(value.sale_price, value.unit)
                }}</span>
                <span
                  class="avatar"
                  v-if="value.item_id != null && value.item_id > 0"
                ></span>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <!--// Seller's product -->
    </div>
  </div>

  <Transition name="fade">
    <div
      class="overlay"
      v-if="this.show_qna_write_popup"
      @click="this.close_qna_write_popup"
    ></div>
  </Transition>
  <Transition name="popup">
    <div class="qna_popup_wrap popup_wrap" v-if="this.show_qna_write_popup">
      <div class="header">
        {{ this.$t("product.detail.qna_title") }}
        <div class="close" @click="this.close_qna_write_popup"></div>
      </div>
      <div class="body">
        <textarea
          v-model="this.qna_write_data.question_content"
          :placeholder="this.$t('product.detail.qna_content_placeholder')"
        ></textarea>
        <input
          type="checkbox"
          id="qna_secret"
          v-model="this.qna_write_data.secret"
        />
        <label for="qna_secret">{{
          this.$t("product.detail.qna_secret")
        }}</label>
      </div>
      <div class="footer">
        <button class="button small" @click="this.click_save_qna">
          {{ this.$t("common.save") }}
        </button>
      </div>
    </div>
  </Transition>

  <Transition name="fade">
    <div
      class="overlay"
      v-if="this.show_image_zoom"
      @click="this.close_image_zoom"
    ></div>
  </Transition>

  <Transition name="fade">
    <div class="image_zoom" v-if="this.show_image_zoom">
      <div class="close" @click="this.close_image_zoom"></div>
      <img :src="this.image_zoom_src" />
    </div>
  </Transition>

  <Spinner v-show="processing" />
</template>

<script>
import { Pagination, Virtual } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue.js";
import Rating from "./Rating.vue";

import "swiper/swiper-bundle.min.css";
import "swiper/modules/pagination/pagination.min.css";
import "swiper/modules/scrollbar/scrollbar.min.css";
import "swiper/modules/virtual/virtual.min.css";

export default {
  name: "Detail",
  components: {
    Swiper,
    SwiperSlide,
    Rating,
  },
  metaInfo() {
    return {
      title: this.$t("meta.title") + " :: " + this.product_name,
      og: {
        title: this.$t("meta.title") + " :: " + this.product_name,
      },
    };
  },
  data: function () {
    return {
      process_queue: [], // 진행중 여부

      // 주요 정보 (데이터 로드 후 매핑)
      product_id: 0,
      product_name: "",
      product_summary: "",
      seller_name: "",
      seller_id: 0,
      product_detail: "",
      product_images: [],
      retail_price: 0,
      sale_price: 0,
      discount_rate: 0.0,
      product_option: null,
      unit: "KRW",
      review_data: [],
      review_page: 1,
      review_total_count: 0, // 리뷰 검색에서 가져온 총 갯수
      review_fully_loaded: false,
      qna_data: [],
      qna_page: 1,
      qna_total_count: 0,
      qna_fully_loaded: false,

      review_count: 0,
      review_score: 0,
      sold_count: 0,

      product_status: "",
      invoice_token: "",

      // 추가 정보
      policy_shipping: "",
      policy_return: "",
      is_policy_shipping_opened: false,
      is_policy_return_opened: false,

      // 계산 정보
      product_option_combination: [], // 선택된 옵션 조합 정보
      total_price: 0, // 최종 가격

      seller_product_data: [],

      // QNA 팝업 관련
      show_qna_write_popup: false,
      qna_write_data: {
        question_content: "",
        secret: true,
      },

      // 이미지 확대 관련
      show_image_zoom: false,
      image_zoom_src: "",

      // 아바타 관련
      avatar_layers: [],
      selected_item_data: [],
      product_item_id: 0,
    };
  },
  setup() {
    return {
      modules: [Pagination, Virtual],
    };
  },
  mounted() {
    this.init_data();
    this.load_product_data();
    this.load_review_data();
    this.load_qna_data();
    this.load_policy_data();
  },
  computed: {
    processing() {
      if (this.process_queue.length <= 0) return false;
      else return true;
    },
  },
  watch: {
    product_option_combination: {
      handler: function () {
        console.log("combination changed.");
        let price = 0;
        for (let i = 0; i < this.product_option_combination.length; i++) {
          let extra_price = this.product_option_combination[i].extra_price;
          let amount = this.product_option_combination[i].amount;

          price += (this.sale_price + extra_price) * amount;
        }

        this.total_price = price;
      },
      deep: true,
    },

    selected_item_data: {
      deep: true,
      handler: function () {
        this.init_avatar_layer();

        for (let i = 0; i < this.selected_item_data.length; i++) {
          let item_data = this.selected_item_data[i].data;

          if (typeof item_data != "undefined") {
            for (let j = 0; j < item_data.length; j++) {
              this.avatar_layers.push({
                layer: item_data[j].layer,
                image_url:
                  process.env.VUE_APP_API_URL +
                  "/resource/get/avatar/data/" +
                  item_data[j].data_id +
                  "/" +
                  item_data[j].item_image,
              });
            }
          }
        }

        this.avatar_layers.sort((a, b) => a.layer - b.layer);
      },
    },
  },
  methods: {
    // 데이터 초기화
    init_data: function () {
      // 제품 아이디 가져오기
      this.product_id = this.$route.params.product_id;

      // 제품 옵션 데이터 초기화
      this.product_option = {
        option: [],
        combination: [],
      };

      // 옵션 조합
      this.product_option_combination = [];

      // 인보이스 토큰
      let naive_token = this.common.generate_random_id();
      this.invoice_token = this.$CryptoJS
        .MD5(naive_token.toString())
        .toString();
    }, //-- init_data

    // 제품 데이터 가져오기
    load_product_data: function () {
      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/product/get",
          {
            product_id: this.product_id,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          let product_data = response.data.data;

          // 데이터를 찾을 수 없는 경우...
          if (typeof product_data == "undefined" || product_data == null) {
            self.$swal
              .fire({
                text: self.$t("product.detail.error_no_product"),
                icon: "error",
              })
              .then(() => {
                self.$router.back();
              });
            return;
          } else if (product_data.product_status == "DELETE") {
            // 삭제된 상품이라면...
            self.$swal
              .fire({
                text: self.$t("product.detail.error_status_delete"),
                icon: "error",
              })
              .then(() => {
                self.$router.back();
              });

            return;
          }

          self.product_id = product_data.product_id;
          self.product_name = product_data.product_name;
          self.product_summary = product_data.product_summary;
          self.seller_name = product_data.seller_name;
          self.seller_id = product_data.seller_id;
          self.sale_price = product_data.sale_price;
          self.retail_price = product_data.retail_price;
          self.discount_rate =
            (self.retail_price - self.sale_price) / self.retail_price;
          if (self.discount_rate < 0) self.discount_rate = 0;

          self.product_detail = product_data.product_detail;

          self.review_count = product_data.review_count;
          self.sold_count = product_data.sold_count;
          self.product_status = product_data.product_status;

          self.unit = product_data.unit;

          self.product_images = [];
          let str_images = product_data.product_images;
          let arr_images = str_images.split("|");
          for (let i = 0; i < arr_images.length; i++) {
            let img_url =
              process.env.VUE_APP_API_URL +
              "/resource/get/product/" +
              self.product_id +
              "/" +
              arr_images[i];
            self.product_images.push(img_url);
          }

          self.review_score = product_data.avg_review_score;

          // 옵션 데이터
          try {
            self.product_option = JSON.parse(product_data.product_option);
          } catch (e) {
            self.product_option = null;
          }

          // 옵션이 없는 경우...
          if (
            self.product_option == null ||
            self.product_option.option.length <= 0
          ) {
            let combination_item = {
              id: 0,
              ids: [],
              amount: 1,
              name: self.product_name,
              extra_price: 0,
            };

            self.product_option_combination = [];
            self.product_option_combination.push(combination_item);
          }

          // 아바타 아이템 아이디
          if (
            typeof product_data.item_id != "undefined" &&
            product_data.item_id != null &&
            product_data.item_id > 0
          ) {
            self.product_item_id = product_data.item_id;
          }

          // 아바타 데이터 로드
          self.load_user_avatar_item_data();

          // 판매자 제품 정보 로드
          self.load_seller_product_data();
        })
        .catch(function (error) {
          alert(error);
        })
        .finally(function () {
          self.process_queue.pop();
        });
    }, //-- load_product_data

    // 리뷰 데이터 가져오기
    load_review_data: function () {
      // 이미 다 불러왔으면 진행안함.
      if (this.review_fully_loaded) return;

      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/review/list",
          {
            product_id: this.product_id,
            page: this.review_page,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          let resp_data = response.data;

          // 첫 페이지에서는 데이터 초기화
          if (self.review_page <= 1) {
            self.review_data = [];
          }

          // 리뷰 데이터가 있다면...
          if (typeof resp_data != "undefined" && resp_data != null) {
            for (let i = 0; i < resp_data.length; i++) {
              resp_data[i].review_image_url1 = "";
              resp_data[i].review_image_url2 = "";
              resp_data[i].review_image_url3 = "";

              if (
                resp_data[i].review_image1 != null &&
                resp_data[i].review_image1 != ""
              ) {
                resp_data[i].review_image_url1 =
                  process.env.VUE_APP_API_URL +
                  "/resource/get/review/" +
                  resp_data[i].review_id +
                  "/" +
                  resp_data[i].review_image1;
              }

              if (
                resp_data[i].review_image2 != null &&
                resp_data[i].review_image2 != ""
              ) {
                resp_data[i].review_image_url2 =
                  process.env.VUE_APP_API_URL +
                  "/resource/get/review/" +
                  resp_data[i].review_id +
                  "/" +
                  resp_data[i].review_image2;
              }

              if (
                resp_data[i].review_image3 != null &&
                resp_data[i].review_image3 != ""
              ) {
                resp_data[i].review_image_url3 =
                  process.env.VUE_APP_API_URL +
                  "/resource/get/review/" +
                  resp_data[i].review_id +
                  "/" +
                  resp_data[i].review_image3;
              }

              // 아바타 썸네일
              resp_data[i].avatar_thumbnail_url = "";
              if (
                typeof resp_data[i].avatar_thumbnail != "undefined" &&
                resp_data[i].avatar_thumbnail != ""
              ) {
                resp_data[i].avatar_thumbnail_url =
                  process.env.VUE_APP_API_URL +
                  "/resource/get/avatar/user/" +
                  resp_data[i].user_id +
                  "/" +
                  resp_data[i].avatar_thumbnail;
              }

              self.review_data.push(resp_data[i]);
            }

            if (resp_data.length <= 0) {
              self.review_fully_loaded = true;
            } else {
              self.review_page++; // 페이지 수 늘리기
            }

            // 총 갯수 정보 헤더에서 가져오기
            try {
              let str_info = response.headers.wkt_extra_info;
              let extra_info = JSON.parse(str_info);
              self.review_total_count = parseInt(extra_info.total_count);
            } catch (e) {
              console.log(e);
            }
          }
        })
        .catch(function (error) {
          alert(error);
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    }, //-- load_review_data

    // Q&A 데이터 가져오기
    load_qna_data: function () {
      // 이미 다 불러왔으면 진행안함.
      if (this.qna_fully_loaded) return;

      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/qna/list",
          {
            product_id: this.product_id,
            page: this.qna_page,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          let resp_data = response.data;

          // 첫 페이지에서는 데이터 초기화
          if (self.qna_page <= 1) {
            self.qna_data = [];
          }

          // 리뷰 데이터가 있다면...
          if (typeof resp_data != "undefined" && resp_data != null) {
            for (let i = 0; i < resp_data.length; i++) {
              // 아바타 썸네일
              resp_data[i].avatar_thumbnail_url = "";
              if (
                typeof resp_data[i].avatar_thumbnail != "undefined" &&
                resp_data[i].avatar_thumbnail != ""
              ) {
                resp_data[i].avatar_thumbnail_url =
                  process.env.VUE_APP_API_URL +
                  "/resource/get/avatar/user/" +
                  resp_data[i].user_id +
                  "/" +
                  resp_data[i].avatar_thumbnail;
              }

              self.qna_data.push(resp_data[i]);
            }

            if (resp_data.length <= 0) {
              self.qna_fully_loaded = true;
            } else {
              self.qna_page++; // 페이지 수 늘리기
            }

            // 총 갯수 정보 헤더에서 가져오기
            try {
              let str_info = response.headers.wkt_extra_info;
              let extra_info = JSON.parse(str_info);
              self.qna_total_count = parseInt(extra_info.total_count);
            } catch (e) {
              console.log(e);
            }
          }
        })
        .catch(function (error) {
          alert(error);
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    }, //-- load_qna_data

    select_option: function (index, id) {
      let self = this;
      if (!this.product_option.option[index]) return;

      // 선택처리
      for (
        let i = 0;
        i < this.product_option.option[index].values.length;
        i++
      ) {
        this.product_option.option[index].values[i].selected = false;
        let option_id = this.product_option.option[index].values[i].id;

        if (option_id.toString() == id.toString()) {
          this.product_option.option[index].values[i].selected = true;
        }
      }

      if (
        this.product_option != null &&
        this.product_option.option.length > 0
      ) {
        // 선택된 값 가져오기
        let selected_ids = [];
        for (let i = 0; i < this.product_option.option.length; i++) {
          for (
            let j = 0;
            j < this.product_option.option[i].values.length;
            j++
          ) {
            if (this.product_option.option[i].values[j].selected) {
              selected_ids.push(this.product_option.option[i].values[j].id);
              break;
            }
          }
        }

        // 선택이 완료된 경우
        if (selected_ids.length == this.product_option.option.length) {
          // 이미 추가한 조합에 있는지 조사
          for (let i = 0; i < this.product_option_combination.length; i++) {
            let combination_ids = this.product_option_combination[i].ids;

            let match_counter = 0;
            for (let k = 0; k < selected_ids.length; k++) {
              for (let n = 0; n < combination_ids.length; n++) {
                if (
                  selected_ids[k].toString() === combination_ids[n].toString()
                ) {
                  match_counter++;
                  break;
                }
              }
            }

            if (match_counter == selected_ids.length) {
              this.$swal.fire({
                text: this.$t("product.detail.error_already_chosen_option"),
                icon: "warning",
              });

              // 선택 해제 처리
              this.clear_selected_option();

              return;
            }
          }

          // 옵션 조합 이름 만들기
          let names = [];
          for (let i = 0; i < selected_ids.length; i++) {
            let value_id = selected_ids[i];

            for (
              let j = 0;
              j < this.product_option.option[i].values.length;
              j++
            ) {
              let id = this.product_option.option[i].values[j].id;
              let name = this.product_option.option[i].values[j].value;
              if (value_id == id) {
                names.push(name);
                break;
              }
            }
          }

          // 사전 설정된 조합 정보를 찾아보고 해당 조합의 상태 및 추가 가격 정보 가져오기
          let combination_id = 0;
          let extra_price = 0;
          let combination_status = "ACTIVE";
          let combination_found = false;
          for (let i = 0; i < this.product_option.combination.length; i++) {
            let ids = this.product_option.combination[i].ids;

            let match_counter = 0;
            for (let k = 0; k < selected_ids.length; k++) {
              for (let n = 0; n < ids.length; n++) {
                if (selected_ids[k].toString() === ids[n].toString()) {
                  match_counter++;
                  break;
                }
              }
            }

            if (match_counter == selected_ids.length) {
              combination_found = true;

              combination_id = this.product_option.combination[i].id;
              extra_price = parseFloat(
                this.product_option.combination[i].extra_price
              );
              combination_status = this.product_option.combination[i].status;
              break;
            }
          }

          if (!combination_found || combination_status != "ACTIVE") {
            this.$swal.fire({
              text: self.$t("product.detail.error_not_sale_option"),
              icon: "warning",
            });

            // 선택 해제 처리
            this.clear_selected_option();

            return;
          }

          // 조합 아이템 만들기
          let selected_combination = {
            id: combination_id,
            ids: selected_ids,
            amount: 1,
            name: names.join(", "),
            extra_price: extra_price,
          };

          // 조합 정보 추가하기
          this.product_option_combination.push(selected_combination);
        }
      }
    }, //-- select_option

    // 옵션 선택 해제 처리
    clear_selected_option: function () {
      for (let i = 0; i < this.product_option.option.length; i++) {
        for (let j = 0; j < this.product_option.option[i].values.length; j++) {
          this.product_option.option[i].values[j].selected = false;
        }
      }
    }, //-- clear_selected_option

    // 옵션 선택 수량 줄이기
    decrease_combination_amount: function (id) {
      for (let i = 0; i < this.product_option_combination.length; i++) {
        let str_id = this.product_option_combination[i].id.toString();

        if (str_id === id.toString()) {
          this.product_option_combination[i].amount--;

          // 수량은 1보다 작을 수 없기에...
          if (this.product_option_combination[i].amount < 1) {
            this.product_option_combination[i].amount = 1;
          }
          return;
        }
      }
    }, //-- decrease_combination_amount

    // 옵션 선택 수량 늘리기
    increase_combination_amount: function (id) {
      for (let i = 0; i < this.product_option_combination.length; i++) {
        let str_id = this.product_option_combination[i].id.toString();

        if (str_id === id.toString()) {
          this.product_option_combination[i].amount++;
          return;
        }
      }
    }, //-- increase_combination_amount

    // 옵션 선택 삭제하기
    remove_option_combination: function (id) {
      // 이미 선택된 조합인지 조사
      for (let i = 0; i < this.product_option_combination.length; i++) {
        let str_id = this.product_option_combination[i].id.toString();

        // 해당 조합을 삭제
        if (str_id === id.toString()) {
          this.product_option_combination.splice(i, 1);
          return;
        }
      }
    }, //-- remove_option_combination

    // 카트에 담기
    add_to_cart: function () {
      let self = this;

      if (!this.product_option_combination.length) {
        self.$swal.fire({
          text: self.$t("product.detail.error_select_option"),
          icon: "error",
        });
        return;
      }

      this.$swal
        .fire({
          text: this.$t("product.detail.confirm_cart"),
          icon: "question",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            // 장바구니 데이터 만들기
            let cart_data = [];
            for (let i = 0; i < self.product_option_combination.length; i++) {
              let now = new Date();
              let cart = {
                cart_id: self.common.generate_random_id(),
                product_id: self.product_id,
                product_option: self.product_option_combination[i].name,
                product_count: self.product_option_combination[i].amount,
                combination_id: self.product_option_combination[i].id,
                cart_timestamp: now.getTime(),
              };

              cart_data.push(cart);
            }

            // 저장소에 장바구니 데이터 추가
            let user_cart = self.storage.get_user_cart();

            // 데이터 가공
            for (let i = 0; i < cart_data.length; i++) {
              let storage_cart_item = {
                cart_id: cart_data[i].cart_id,
                product_id: cart_data[i].product_id,
                product_count: cart_data[i].product_count,
                product_option: cart_data[i].product_option,
                product_option_combination_id: cart_data[i].combination_id,
                cart_timestamp: cart_data[i].cart_timestamp, // 타임스탬프
              };

              // 이미 존재하는 정보인지 검사
              let already_exists = false;
              console.log(user_cart);
              console.log(storage_cart_item);
              for (let j = 0; j < user_cart.length; j++) {
                if (
                  parseInt(user_cart[j].product_id) ==
                    parseInt(storage_cart_item.product_id) &&
                  parseInt(user_cart[j].product_option_combination_id) ==
                    parseInt(storage_cart_item.product_option_combination_id)
                ) {
                  already_exists = true;
                  console.log("cart found");
                  break;
                }
              }

              // 이미 존재하지 않는 경우에만 추가
              if (!already_exists) {
                // 기존 데이터에서 맨 앞에 추가
                user_cart.unshift(storage_cart_item);
              }
            } //-- for

            // Storage에 저장
            self.storage.set_user_cart(user_cart);

            // 로그인 했으면 DB에 추가
            if (self.storage.is_logged_in()) {
              self.add_to_cart_server(cart_data); // 서버에 데이터 추가
            } else {
              self.complete_add_to_cart();
            } //-- else
          } //-- isConfirmed
        }); //-- swal then
    }, //-- add_to_cart

    // 카트 정보 서버에 반영
    add_to_cart_server: function (cart_data) {
      let self = this;
      self.process_queue.push(1);

      self.axios
        .post(
          process.env.VUE_APP_API_URL + "/cart/add",
          {
            user_id: self.storage.get_user_id(),
            cart_data: cart_data,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          let result = response.data.result;

          if (result == "OK") {
            self.complete_add_to_cart();
          } else if (result == "ERR_NO_PARAM") {
            self.$swal.fire({
              text: self.$t("common.error_no_param"),
              icon: "error",
            });
            return;
          }
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    }, //-- add_to_cart_server

    // 장바구니 추가 후속 처리
    complete_add_to_cart: function () {
      let self = this;

      this.$swal
        .fire({
          text: self.$t("product.detail.confirm_move_to_cart"),
          icon: "question",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            self.$router.push("/cart");
          }
        });
    }, //-- complete_add_to_cart

    // 바로 구매하기
    buy_now: function () {
      let self = this;

      // 로그인 안되어 있으면 아무것도 안함.
      if (!this.storage.is_logged_in()) {
        this.$swal
          .fire({
            text: self.$t("auth.error_no_login_ask"),
            icon: "error",
            showCancelButton: true,
          })
          .then((result) => {
            if (result.isConfirmed) {
              // 리턴 URL 지정 후 로그인 페이지로 이동
              let return_url = window.location.pathname;
              if (typeof return_url != "undefined" && return_url != "") {
                this.$router.push({
                  name: "login",
                  query: { return_url: encodeURIComponent(return_url) },
                });
              }
            }
          });
        return;
      }

      // 상품 옵션 선택 여부 확인
      if (!this.product_option_combination.length) {
        self.$swal.fire({
          text: self.$t("product.detail.error_select_option"),
          icon: "error",
        });
        return;
      }

      this.$swal
        .fire({
          text: this.$t("product.detail.confirm_buy_now"),
          icon: "question",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            // 인보이스 데이터 만들기
            let invoice_data = [];
            for (let i = 0; i < self.product_option_combination.length; i++) {
              let invoice = {
                cart_id: 0, // 장바구니에서 인보이스를 생성하는것이 아니면 0보다 작은 수
                product_id: self.product_id,
                product_option: self.product_option_combination[i].name,
                product_count: self.product_option_combination[i].amount,
                combination_id: self.product_option_combination[i].id,
              };

              invoice_data.push(invoice);
            }

            self.save_invoice(invoice_data);
          } //-- isConfirmed
        }); //-- swal then
    }, //-- buy_now

    // 인보이스를 서버에 저장
    save_invoice: function (invoice_data) {
      let self = this;
      self.process_queue.push(1);

      self.axios
        .post(
          process.env.VUE_APP_API_URL + "/invoice/add",
          {
            user_id: self.storage.get_user_id(),
            invoice_token: self.invoice_token,
            invoice_data: invoice_data,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          let result = response.data.result;

          if (result == "OK") {
            let route_data = {
              name: "order",
              params: { invoice_token: self.invoice_token },
            };
            self.$router.push(route_data);
          } else if (result == "ERR_NO_PARAM") {
            self.$swal.fire({
              text: self.$t("common.error_no_param"),
              icon: "error",
            });
            return;
          }
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    }, //-- save_invoice

    // 판매자 제품 가져오기
    load_seller_product_data: function () {
      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/product/list",
          {
            seller_id: self.seller_id,
            page: 1,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          self.seller_product_data = response.data;

          let data = response.data;
          self.seller_product_data = [];

          for (let i = 0; i < data.length; i++) {
            let item = data[i];
            self.seller_product_data.push(item);
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    }, //-- load_seller_product_data

    scroll_to_element: function (section) {
      let obj = null;
      if (section == "DETAIL") {
        obj = this.$refs.detail;
      } else if (section == "REVIEW") {
        obj = this.$refs.review;
      } else if (section == "QNA") {
        obj = this.$refs.qna;
      } else if (section == "EXTRA") {
        obj = this.$refs.extra;
      }

      if (obj) {
        obj.scrollIntoView({ block: "start", behavior: "smooth" });
      }
    },

    // 질문 작성 데이터 초기화
    init_qna_write_data: function () {
      this.qna_write_data = {
        product_id: 0,
        user_id: 0,
        question_content: "",
        secret: true,
      };
    },

    // 질문 작성 창 열기
    open_qna_write_popup: function () {
      this.init_qna_write_data();
      this.show_qna_write_popup = true;
    },

    // 질문 작성 창 닫기
    close_qna_write_popup: function () {
      // 로그인 안되어 있으면 아무것도 안함.
      if (!this.storage.is_logged_in()) {
        this.$swal
          .fire({
            text: self.$t("auth.error_no_login_ask"),
            icon: "error",
            showCancelButton: true,
          })
          .then((result) => {
            if (result.isConfirmed) {
              // 리턴 URL 지정 후 로그인 페이지로 이동
              let return_url = window.location.pathname;
              if (typeof return_url != "undefined" && return_url != "") {
                this.$router.push({
                  name: "login",
                  query: { return_url: encodeURIComponent(return_url) },
                });
              }
            }
          });
        return;
      }

      this.show_qna_write_popup = false;
      this.init_qna_write_data();
    },

    // 질문 저장 버튼 클릭 처리
    click_save_qna: function () {
      let self = this;

      // 로그인 안되어 있으면 아무것도 안함.
      if (!this.storage.is_logged_in()) {
        this.$swal
          .fire({
            text: self.$t("auth.error_no_login_ask"),
            icon: "error",
            showCancelButton: true,
          })
          .then((result) => {
            if (result.isConfirmed) {
              // 리턴 URL 지정 후 로그인 페이지로 이동
              let return_url = window.location.pathname;
              if (typeof return_url != "undefined" && return_url != "") {
                this.$router.push({
                  name: "login",
                  query: { return_url: encodeURIComponent(return_url) },
                });
              }
            }
          });
        return;
      }

      if (this.qna_write_data.question_content.trim().length < 10) {
        self.$swal.fire({
          text: self.$t("product.detail.error_qna_content"),
          icon: "error",
        });
        return;
      }

      this.$swal
        .fire({
          text: this.$t("common.confirm_save"),
          icon: "question",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.isConfirmed) {
            self.add_qna_data();
          }
        }); //-- swal
    }, //-- click_save_qna

    // 질문 데이터 서버에 저장
    add_qna_data: function () {
      if (this.qna_write_data.question_content.trim().length < 10) return;
      if (!this.storage.is_logged_in()) return;

      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/qna/add",
          {
            user_id: self.storage.get_user_id(),
            product_id: self.product_id,
            question_content: self.qna_write_data.question_content,
            secret: this.qna_write_data.secret ? "YES" : "NO",
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          if (response.data.result != "OK") {
            self.$swal.fire({
              text: self.$t("common.error_occur"),
              icon: "error",
            });
            return;
          }

          // 질문 작성 팝업 닫기
          self.close_qna_write_popup();

          // 주문 정보 다시 로드하기
          self.qna_fully_loaded = false;
          self.qna_page = 1;
          self.load_qna_data();
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    }, //-- add_qna_data

    close_image_zoom: function () {
      this.show_image_zoom = false;
      this.image_zoom_src = "";
    },
    open_image_zoom: function (img_url) {
      this.show_image_zoom = true;
      this.image_zoom_src = img_url;
    },

    goto_store: function () {
      if (typeof this.seller_id != "undefined" && this.seller_id > 0) {
        this.$router.push("/store/" + this.seller_id);
      }
    }, //-- goto_store

    // 규정 정보 가져오기
    load_policy_data: function () {
      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/policy/get",
          {},
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          let data = response.data;

          if (typeof data.SHIPPING != "undefined") {
            self.policy_shipping = data.SHIPPING.policy_content;
          }

          if (typeof data.RETURN != "undefined") {
            self.policy_return = data.RETURN.policy_content;
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    }, //-- load_policy_data

    toggle_policy: function (type) {
      if (type == "SHIPPING") {
        this.is_policy_shipping_opened = !this.is_policy_shipping_opened;
      } else if (type == "RETURN") {
        this.is_policy_return_opened = !this.is_policy_return_opened;
      }
    }, //-- toggle_policy

    // 아바타 레이어 초기화
    init_avatar_layer: function () {
      this.avatar_layers.splice(0, this.avatar_layers.length);
    },

    // 사용자 아바타 정보 먼저 가져오기 (있는지 확인 등)
    load_user_avatar_item_data: function () {
      // 로그인 안했으면 랜덤생성
      if (!this.storage.is_logged_in()) {
        this.load_avatar_item_data();
        return;
      }

      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/auth/get",
          {
            user_id: this.storage.get_user_id(),
            required: "YES",
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          if (response.data.result != "OK") {
            self.$swal.fire({
              text: this.$t("common.error_occur"),
              icon: "error",
            });

            return;
          }

          let data = response.data.data;
          if (
            typeof data.avatar_id != "undefined" &&
            parseInt(data.avatar_id) > 0
          ) {
            self.load_avatar_item_map(parseInt(data.avatar_id));
          } else {
            self.load_avatar_item_data(); // 정보가 없으면 랜덤 생성으로 넘어감
          }
        })
        .catch(function (error) {
          alert(error);
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    }, //-- load_user_avatar_item_data

    // 아바타 매핑 데이터 가져오기
    load_avatar_item_map: function (avatar_id) {
      if (!this.storage.is_logged_in()) return;

      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/avatar/map/list",
          {
            user_id: this.storage.get_user_id(),
            avatar_id: avatar_id,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          let resp_data = response.data;

          // 데이터
          let map_data = [];

          // 데이터가 있다면...
          if (typeof resp_data != "undefined" && resp_data != null) {
            for (let i = 0; i < resp_data.length; i++) {
              resp_data[i].data = resp_data[i].item_data;

              let item = {
                group_name: resp_data[i].group_name,
                group_id: resp_data[i].group_id,
                item_id: resp_data[i].item_id,
                data: resp_data[i].item_data,
              };

              map_data.push(item);
            }

            self.selected_item_data = map_data;

            // 제품 아바타 아이템 정보 가져와서 해당 부분 치환
            self.load_product_avatar_item();
          }
        })
        .catch(function (error) {
          alert(error);
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    }, //-- load_avatar_item_map

    // 전체 아바타 데이터 가져와서 랜덤으로 생성
    load_avatar_item_data: function () {
      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/avatar/list",
          {
            required: "YES",
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          let resp_data = response.data;

          // 데이터
          let avatar_data = [];

          // 데이터가 있다면...
          if (typeof resp_data != "undefined" && resp_data != null) {
            for (let i = 0; i < resp_data.length; i++) {
              avatar_data.push(resp_data[i]);
            }
          }

          // 랜덤 선택
          let chosen_data = self.select_random_avatar_item_layers(avatar_data);
          self.selected_item_data = chosen_data;

          // 제품 아바타 아이템 정보 가져와서 해당 부분 치환
          self.load_product_avatar_item();
        })
        .catch(function (error) {
          alert(error);
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    }, //-- load_avatar_item_data

    // 랜덤으로 아바타 데이터 생성하기
    select_random_avatar_item_layers: function (data) {
      let ret = []; // {group_id: 0, item_id: 0, data: [],}, ...

      for (let i = 0; i < data.length; i++) {
        let random_index = Math.floor(Math.random() * data[i].item.length);
        let item_data = JSON.parse(
          JSON.stringify(data[i].item[random_index].data)
        );

        let item = {
          group_name: data[i].group_name,
          group_id: data[i].group_id,
          item_id: data[i].item[random_index].item_id,
          data: item_data,
        };

        ret.push(item);
      }

      return ret;
    }, //-- select_random_avatar_item

    load_product_avatar_item: function () {
      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/avatar/item/get",
          {
            item_id: self.product_item_id,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          let resp_data = response.data;

          // 데이터가 있다면...
          if (typeof resp_data != "undefined" && resp_data != null) {
            for (let i = 0; i < self.selected_item_data.length; i++) {
              // 그룹 아이디가 있으면 삭제
              if (self.selected_item_data[i].group_id == resp_data.group_id) {
                self.selected_item_data.splice(i, 1);
                i--;
              } else if (
                resp_data.group_name == "TOP" &&
                resp_data.item_name.toLowerCase().includes("dress")
              ) {
                // 드레스인 경우 바지도 제외시키기...
                if (
                  self.selected_item_data[i].group_name
                    .toLowerCase()
                    .includes("pant")
                ) {
                  self.selected_item_data.splice(i, 1);
                  i--;
                }
              }
            }

            let new_item = {
              group_id: resp_data.group_id,
              item_id: resp_data.item_id,
              data: resp_data.item_data,
            };

            self.selected_item_data.push(new_item);
          }
        })
        .catch(function (error) {
          alert(error);
        })
        .finally(function () {
          self.process_queue.pop();
        }); //-- axios
    }, //-- select_random_avatar_item
  },
};
</script>

<style scoped>
.detail {
  position: relative;
}

.detail > .wrap {
  width: 1200px;
  max-width: 100%;
  margin: 0 auto;
  padding: 2rem;
}

.detail > .wrap > .top {
  padding: 0;
  font-size: 1rem;
  font-weight: 400;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.detail > .wrap > .top > .image {
  position: relative;
  width: 45%;
  margin-right: 2rem;
}
.detail > .wrap > .top > .image::after {
  content: "";
  display: block;
  padding-top: 100%;
}
.detail > .wrap > .top > .image :deep(.swiper) {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.detail > .wrap > .top > .image :deep(.swiper-slide) {
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 18px;
  background: #fff;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  overflow: hidden;
}
.detail > .wrap > .top > .image :deep(.swiper-slide) .image {
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 18px;
  background: #fff;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.detail > .wrap > .top > .image :deep(.swiper-pagination-bullet-active) {
  background: #e74356;
}

.detail > .wrap > .top > .info {
  width: calc(100% - 45% - 2rem - 0.1px);
}
.detail > .wrap > .top > .info .section {
  margin-bottom: 0.8rem;
}

.detail > .wrap > .top > .info > .brand {
  font-size: 1.2rem;
  font-weight: 700;

  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.detail > .wrap > .top > .info > .brand > .name {
  padding: 0;
}
.detail > .wrap > .top > .info > .brand > .icon {
  width: 1.6rem;
  height: 1.6rem;
  margin: 0 0 0 0.6rem;

  background: url("../../assets/img/icon_store.svg") no-repeat center center;
  background-size: contain;

  cursor: pointer;
}
.detail > .wrap > .top > .info > .name {
  font-size: 1.4rem;
  font-weight: 700;
}
.detail > .wrap > .top > .info > .brief {
  font-size: 1.1rem;
  font-weight: 300;
}
.detail > .wrap > .top > .info > .price {
}
.detail > .wrap > .top > .info > .price > .percent {
  font-size: 1.3rem;
  font-weight: 700;
  color: #e74356;
  margin-right: 1rem;
}
.detail > .wrap > .top > .info > .price > .sale {
  font-size: 1.3rem;
  font-weight: 700;
  margin-right: 1rem;
}
.detail > .wrap > .top > .info > .price > .retail {
  font-size: 1.2rem;
  font-weight: 400;
  color: #808080;
  text-decoration: line-through;
}

.detail > .wrap > .top > .info > .user {
}
.detail > .wrap > .top > .info > .user > ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.detail > .wrap > .top > .info > .user > ul > li {
  margin-right: 1.4rem;
}
.detail > .wrap > .top > .info > .user > ul > li > .number {
  font-size: 1.1rem;
  font-weight: 700;
}

.detail > .wrap > .top > .info > .shipping {
  font-size: 1.1rem;
  font-weight: 700;
}
.detail > .wrap > .top > .info > .marketing {
  color: #808080;
}

.detail > .wrap > .top > .info > .option {
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
}
.detail > .wrap > .top > .info > .option > .group {
  padding: 0.6rem 0;
  border-bottom: 1px solid #dedede;
}
.detail > .wrap > .top > .info > .option > .group:last-child {
  border-bottom: none;
}
.detail > .wrap > .top > .info > .option > .group > .title {
  font-size: 0.875rem;
  font-weight: 700;
  margin-bottom: 0.4rem;
}
.detail > .wrap > .top > .info > .option > .group > .data {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}
.detail > .wrap > .top > .info > .option > .group > .data > .item {
  display: inline-block;
  padding: 0.4rem 1rem;
  border-radius: 0.8rem;
  border: 1px solid #dedede;
  margin: 0 0.4rem 0.4rem 0;
  background: #fff;
  cursor: pointer;
}
.detail > .wrap > .top > .info > .option > .group > .data > .item.selected {
  border: 1px solid #333;
}

.detail > .wrap > .top > .info > .selection {
  border-bottom: 1px solid #dedede;
}
.detail > .wrap > .top > .info > .selection > .combination {
  position: relative;
  border-bottom: 1px solid #dedede;
  padding: 0.8rem 0;
  text-align: right;

  display: flex;
  justify-content: space-around;
  align-items: center;
}
.detail > .wrap > .top > .info > .selection > .combination:last-child {
  border-bottom: none;
}
.detail > .wrap > .top > .info > .selection > .combination > .amount {
  width: 100px;

  display: flex;
  justify-content: space-between;
  align-items: stretch;
}
.detail > .wrap > .top > .info > .selection > .combination > .amount > .minus,
.detail > .wrap > .top > .info > .selection > .combination > .amount > .plus {
  background: url("~@/assets/img/icon_minus.svg") no-repeat center center;
  background-size: 35% auto;
  border: 1px solid #dedede;

  width: 32px;

  cursor: pointer;
}
.detail > .wrap > .top > .info > .selection > .combination > .amount > .plus {
  background-image: url("~@/assets/img/icon_plus.svg");
}
.detail
  > .wrap
  > .top
  > .info
  > .selection
  > .combination
  > .amount
  > input[type="number"] {
  border: none;
  border-top: 1px solid #dedede;
  border-bottom: 1px solid #dedede;
  border-radius: 0;
  width: calc(100% - 64px);

  text-align: center;
  padding: 0.2rem;
  margin: 0;
  outline: none;

  font-size: 1rem;
}

.detail
  > .wrap
  > .top
  > .info
  > .selection
  > .combination
  > .amount
  > input[type="number"]::-webkit-outer-spin-button,
.detail
  > .wrap
  > .top
  > .info
  > .selection
  > .combination
  > .amount
  > input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.detail > .wrap > .top > .info > .selection > .combination > .value {
  padding: 0 0 0 2rem;
  width: calc(100% - 100px - 18px);

  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.detail > .wrap > .top > .info > .selection > .combination > .value > .name {
  display: block;
  text-align: left;
  flex-grow: 1;
}
.detail > .wrap > .top > .info > .selection > .combination > .value > .price {
  display: block;
  margin-left: 2rem;
}
.detail > .wrap > .top > .info > .selection > .combination > .remove {
  display: block;

  background: #333 url("~@/assets/img/icon_delete_white.svg") no-repeat center
    center;
  background-size: 40% auto;

  width: 18px;
  height: 18px;
  border-radius: 50%;

  margin-left: 2rem;

  cursor: pointer;
}

.detail > .wrap > .top > .info > .total {
  padding: 0.4rem 0;

  display: flex;
  justify-content: space-around;
  align-items: center;
}
.detail > .wrap > .top > .info > .total > .title {
  font-size: 1.2rem;
  font-weight: 700;

  width: 35%;
}
.detail > .wrap > .top > .info > .total > .price {
  font-size: 1.4rem;
  font-weight: 700;
  text-align: right;

  width: 65%;
}

.detail > .wrap > .top > .info > .buttons {
  position: relative;
  display: flex;
  justify-content: space-around;
  align-items: stretch;
}
.detail > .wrap > .top > .info > .buttons > .button {
  width: 33.33%;
  margin-right: 0.3rem;
}
.detail > .wrap > .top > .info > .buttons > .button:last-child {
  margin-right: 0;
}
.detail > .wrap > .top > .info > .buttons > .button.purchase {
  width: 60%;
}
.detail > .wrap > .top > .info > .buttons > .button.cart {
  width: 40%;
  background-image: url("../../assets/img/icon_cart_plus.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 35%;
}
.detail > .wrap > .top > .info > .buttons > .button.cart:hover {
  background-image: url("../../assets/img/icon_cart_plus_white.svg");
}
.detail > .wrap > .top > .info > .buttons > .button.wish {
  width: 20%;
}

.detail > .wrap > .link {
  position: sticky;
  z-index: 8;
  top: 64px;
  margin: 3rem 0 0 0;
  border-bottom: 1px solid #dedede;
  background: #fff;

  display: flex;
  justify-content: space-around;
  align-items: baseline;
}

.detail > .wrap > .link > .menu {
  width: calc(100% / 4);
  text-align: center;
  font-size: 1.2rem;
  font-weight: 700;
  padding: 1rem 0;

  cursor: pointer;
}
.detail > .wrap > .link > .menu:hover {
  text-decoration: underline;
}

.detail > .wrap > .avatar {
  margin: 3rem 0 1rem 0;
}
.detail > .wrap > .avatar > .character {
  position: relative;
  height: 640px;
  overflow: hidden;

  background-image: url("../../assets/img/img_fit_back.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.detail > .wrap > .avatar > .character > .title {
  position: relative;
  font-size: 1.6rem;
  font-weight: 700;
  margin: 1.2rem;
  padding: 0;
}
.detail > .wrap > .avatar > .character > .title > .help {
  position: relative;
  display: inline-block;
  width: 1.2rem;
  height: 1.2rem;

  border-radius: 50%;
  background-color: #333;
  background-image: url("../../assets/img/icon_question_white.svg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: auto 50%;

  cursor: pointer;
}
.detail > .wrap > .avatar > .character > .title > .help > .bubble {
  position: absolute;
  top: calc(100% + 12px);
  left: -4.2rem;
  background: #333;
  color: #fff;
  width: 320px;
  max-width: 60vw;
  border-radius: 0.4rem;
  padding: 1rem;

  font-size: 1rem;
  font-weight: 400;

  display: none;
  opacity: 0;

  user-select: none;
  pointer-events: none;

  animation: bubble-appear 300ms ease-out forwards;
}
@keyframes bubble-appear {
  from {
    opacity: 0;
    transform: translate(0, 50%);
  }
  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}
.detail > .wrap > .avatar > .character > .title > .help:hover > .bubble {
  display: block;
  opacity: 1;
}
.detail > .wrap > .avatar > .character > .title > .help > .bubble:after {
  content: "";
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-width: 0 0 12px 12px;
  border-color: transparent transparent #333 transparent;
  top: -12px;
  left: 4.2rem;
  margin-left: -6px;

  animation: bubble-appear300ms ease-out forwards;
}

.detail > .wrap > .avatar > .character > .layer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;

  user-select: none;

  transform: scale(1.02);
  transition: transform 0.4s ease-in-out;
}

.detail > .wrap > .detail {
  padding: 1rem 0;
}
.detail > .wrap > .detail :deep(img) {
  max-width: 100%;
}

.detail > .wrap > .subtitle {
  position: relative;
  font-size: 1.2rem;
  font-weight: 700;

  padding: 0.8rem 0;
  margin: 3rem 0 0 0;
  border-bottom: 1px solid #ccc;
}
.detail > .wrap > .subtitle > .more {
  position: absolute;
  right: 0;
  bottom: 50%;
  transform: translate(0, 50%);
  font-size: 1rem;
  font-weight: 400;

  padding-right: 1rem;

  background-image: url("../../assets/img/icon_right.svg");
  background-repeat: no-repeat;
  background-position: right 0.2rem center;
  background-size: auto 0.8rem;
}
.detail > .wrap > .subtitle > .more a {
  text-decoration: none;
  color: #000;
}
.detail > .wrap > .subtitle > .more a:hover {
  text-decoration: underline;
}

.detail > .wrap > .content {
  padding: 1rem 0;
}

.detail > .wrap > .content button.more {
  margin: 1.6rem auto 0 auto;
  display: block;
}

.detail > .wrap > .content > ul.review {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.detail > .wrap > .content > ul.review > li {
  padding: 0;
  margin: 0;
  border-bottom: 1px solid #dedede;
}
.detail > .wrap > .content > ul.review > li.empty {
  text-align: center;
  padding: 3rem 0;
}
.detail > .wrap > .content > ul.review > li > .header {
  position: relative;
}
.detail > .wrap > .content > ul.review > li > .header > .profile {
  display: inline-block;
  vertical-align: middle;
  width: 2.4rem;
  height: 2.4rem;
  margin: 0 1rem 0 0;
  border-radius: 50%;

  background-color: #efefef;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.detail > .wrap > .content > ul.review > li > .header > .id {
  display: inline-block;
  vertical-align: middle;
  font-weight: 700;
  padding: 0.8rem 0;
}
.detail > .wrap > .content > ul.review > li > .header > .flag {
  display: inline-block;
  vertical-align: middle;
  width: 1.4rem;
  margin: 0 0 0 1rem;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.35);
}
.detail > .wrap > .content > ul.review > li > .header > .date {
  position: absolute;
  right: 0;
  bottom: 50%;
  transform: translate(0, 50%);

  padding: 0.8rem 0;
  color: #808080;
}
.detail > .wrap > .content > ul.review > li > .body {
  position: relative;
  padding: 0.8rem 0;
  min-height: 96px;
}
.detail > .wrap > .content > ul.review > li > .body > .score {
  width: 120px;
  margin-bottom: 0.8rem;
}
.detail > .wrap > .content > ul.review > li > .body > .spec {
  padding: 0 8rem 0.8rem 0;
}
.detail > .wrap > .content > ul.review > li > .body > .spec > ul {
  list-style-type: none;
  padding: 0;
  margin: 0;

  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.detail > .wrap > .content > ul.review > li > .body > .spec > ul > li {
  position: relative;
  padding: 0 1rem 0 0;
  margin: 0 1rem 0 0;
  color: #808080;
}
.detail > .wrap > .content > ul.review > li > .body > .spec > ul > li::after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  bottom: 50%;
  transform: translate(0, 50%);
  background: #ccc;

  width: 1px;
  height: 60%;
}
.detail
  > .wrap
  > .content
  > ul.review
  > li
  > .body
  > .spec
  > ul
  > li:last-child:after {
  display: none;
}
.detail > .wrap > .content > ul.review > li > .body > .comment {
  padding: 0.8rem 8rem 0 0;
  white-space: pre-line;
}
.detail > .wrap > .content > ul.review > li > .body > .image_wrap {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0.8rem 0 0 0;
}
.detail > .wrap > .content > ul.review > li > .body > .image_wrap > .image {
  width: 86px;
  height: 86px;
  margin: 0 0.4rem 0 0;
  background: #dedede;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  cursor: pointer;
}
.detail > .wrap > .content > ul.review > li > .body > .image {
  position: absolute;
  right: 0;
  bottom: 50%;
  transform: translate(0, 50%);
  width: 86px;
  height: 86px;
  background: #dedede;
}

.detail > .wrap > .subtitle > button.qna_write {
  position: absolute;
  right: 0;
  bottom: 50%;
  transform: translate(0, 50%);

  font-size: 0.875rem;
  padding: 0.4rem 0.8rem;
}
.detail > .wrap > .content > ul.qna {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.detail > .wrap > .content > ul.qna > li {
  display: block;
  margin: 0;
  padding: 1rem 0;
  border-bottom: 1px solid #dedede;
}
.detail > .wrap > .content > ul.qna > li.empty {
  text-align: center;
  padding: 3rem 0;
}
.detail > .wrap > .content > ul.qna > li > .header {
  position: relative;
  padding: 0 0 0.8rem 0;
}
.detail > .wrap > .content > ul.qna > li > .header > .profile {
  display: inline-block;
  vertical-align: middle;
  width: 2.4rem;
  height: 2.4rem;
  margin: 0 1rem 0 0;
  border-radius: 50%;

  background-color: #efefef;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.detail > .wrap > .content > ul.qna > li > .header > .name {
  display: inline-block;
  vertical-align: middle;
  font-weight: 700;
}
.detail > .wrap > .content > ul.qna > li > .header > .flag {
  display: inline-block;
  vertical-align: middle;
  width: 1.4rem;
  margin: 0 0 0 1rem;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.35);
}
.detail > .wrap > .content > ul.qna > li > .header > .status {
  display: inline-block;
  vertical-align: middle;

  padding: 0.3rem 0.6rem;
  margin: 0 0 0 1rem;

  font-size: 0.5rem;
  font-weight: 300;
  background: #333;
  color: #fff;
  border-radius: 0.5rem;
}
.detail > .wrap > .content > ul.qna > li > .header > .date {
  position: absolute;
  right: 0;
  bottom: 50%;
  transform: translate(0, 50%);
  color: #808080;
}
.detail > .wrap > .content > ul.qna > li > .question {
  padding: 0 0 1rem 0;
  white-space: pre-line;
}
.detail > .wrap > .content > ul.qna > li > .question > img.lock {
  vertical-align: middle;
  height: 1rem;
  margin-right: 0.4rem;
}
.detail > .wrap > .content > ul.qna > li > .answer {
  background: #efefef;
  border-radius: 0.4rem;
  overflow: hidden;
}
.detail > .wrap > .content > ul.qna > li > .answer > .header {
  position: relative;
  padding: 0.8rem 1rem;
}
.detail > .wrap > .content > ul.qna > li > .answer > .header > .name {
  font-weight: 700;
}
.detail > .wrap > .content > ul.qna > li > .answer > .header > .date {
  position: absolute;
  right: 1rem;
  bottom: 50%;
  transform: translate(0, 50%);
  color: #808080;
}
.detail > .wrap > .content > ul.qna > li > .answer > .body {
  padding: 0 1rem 0.8rem 1rem;
  white-space: pre-line;
}
.detail > .wrap > .content > ul.qna > li > .answer > .body > img.lock {
  vertical-align: middle;
  height: 1rem;
  margin-right: 0.4rem;
}

.detail > .wrap > .content.policy > ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.detail > .wrap > .content.policy > ul > li {
  position: relative;
}
.detail > .wrap > .content.policy > ul > li > .title {
  position: relative;
  padding: 0.8rem 0;
  margin: 0;
  font-size: 1.1rem;
  font-weight: 700;

  background-image: url("../../assets/img/icon_down.svg");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: auto 40%;

  border-bottom: 1px solid #dedede;

  cursor: pointer;
}
.detail > .wrap > .content.policy > ul > li > .title.opened {
  background-image: url("../../assets/img/icon_up.svg");
}
.detail > .wrap > .content.policy > ul > li .policy_content {
  padding: 0.8rem 0;
  border-bottom: 1px solid #dedede;
}

.qna_popup_wrap > .body > textarea {
  width: 100%;
  height: 180px;
  resize: none;
  margin: 0 0 0.4rem 0;
}
.qna_popup_wrap > .body > input[type="checkbox"] {
  vertical-align: middle;
}
.qna_popup_wrap > .body > label {
  vertical-align: middle;
  margin: 0 0 0 0.4rem;
}

.image_zoom {
  position: fixed;
  right: 50%;
  bottom: 50%;
  transform: translate(50%, 50%);

  max-width: 90vw;
  max-height: 90vh;
  z-index: 99;
}
.image_zoom > .close {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);

  width: 2.4rem;
  height: 2.4rem;
  padding: 0;
  margin: 0;
  border-radius: 50%;
  overflow: hidden;

  background: #333 url("../../assets/img/icon_close_white.svg") no-repeat center
    center;
  background-size: auto 60%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);

  cursor: pointer;
}
.image_zoom > img {
  display: block;
  margin: 0 auto;
  max-width: 100%;
  max-height: 100%;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
}

@media (max-width: 800px) {
  .detail > .wrap {
    padding: 1rem;
  }

  .detail > .wrap > .top {
    display: block;
  }
  .detail > .wrap > .top > .image {
    width: 100%;
    margin: 0 0 2rem 0;
  }
  .detail > .wrap > .top > .info {
    width: auto;
  }

  .detail > .wrap > .link {
    top: 54px;
  }
}

@media (max-width: 300px) {
  .detail > .wrap > .top > .info > .buttons {
    position: relative;
    flex-wrap: wrap;
  }
  .detail > .wrap > .top > .info > .buttons > .button {
    width: 100%;
    margin-right: 0;
  }
  .detail > .wrap > .top > .info > .buttons > .button.purchase {
    width: 100%;
    margin-bottom: 0.4rem;
  }
  .detail > .wrap > .top > .info > .buttons > .button.cart {
    width: 100%;
  }
}
</style>
